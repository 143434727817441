import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import {
  MdArrowForwardIos,
  MdBookmark,
  MdBookmarkBorder,
  MdClose,
  MdMenu,
  MdOutlineShoppingCart,
  MdPerson,
  MdPersonOutline,
  MdSearch,
  MdShoppingCart,
} from "react-icons/md";

import "../styles/Home.css";
import "../styles/Guests.css";
import { isEmpty } from "../functions/isEmpty";
import { useLocation, useNavigate } from "react-router";
import { useRef } from "react";
import Spinner from "../components/CTA/Spinner";

export default function Home({ isMobile, apiURL, authToken }) {
  let navigate = useNavigate();
  let location = useLocation().pathname;
  const [isLoading, setIsLoading] = useState(false);

  const startMotor = async () => {
    setIsLoading(true);
    let dateNow = Date.now();
    let dateNowString = dateNow.toString();
    const resa = await fetch(
      `https://finer-generally-duckling.ngrok-free.app`,
      {
        method: "GET",
        headers: {
          //   Authorization: `Bearer ${authToken?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        /*
        body: JSON.stringify({
          createdAt: dateNowString,
        }),
        */
      }
    );

    await resa.text().then((value) => uploadEnds(value));
  };

  const uploadEnds = async (value) => {
    alert("started");
  };

  // mobile
  return (
    <div className="home">
      <div className="home_topnav">
        <div className="home_topnav_left">Fishy</div>
      </div>
      <div className="home_body">
        <div className="home_body_cta button" onClick={() => startMotor()}>
          Gib her die Scheiße
        </div>
      </div>
    </div>
  );
}

const MenuIcon = styled(MdMenu)`
  font-size: 30px;
  color: #474747;
`;

const CloseIcon = styled(MdClose)`
  font-size: 25px;
  color: #222222;
`;

const SearchIcon = styled(MdSearch)`
  font-size: 25px;
  color: #474747;
`;

const ArrowForwardIcon = styled(MdArrowForwardIos)`
  font-size: 18px;
  color: #474747;
`;
